/** @format */

import {Box, Button, Card, Checkbox, Flex, LoadingOverlay, Text, Textarea, Title,} from "@mantine/core";
import styled from "styled-components";
import MedicationCard from "../components/MedicationCard";
import {BILL_SUMMARY} from "../../../constants";
import {useAppData} from "../../../contexts/AppContext";
import {modals} from "@mantine/modals";
import ConfirmModalFrame from "../../../components/ConfirmModalFrame";
import {onOrderStatusChange} from "../../../repositories/firebase";
import {useEffect, useState} from "react";
import {BatchRuleActionType, OrderStatus} from "../../../constants/enums";
import {db} from "../../../firebase";
import {doc, getDoc} from 'firebase/firestore';

function Prescription() {
  const { data, order, setData } = useAppData();

  const [submitting, setSubmitting] = useState(false);
  const [showRushOrder, setShowRushOrder] = useState(true);
  const pharmacyCall = () => {
    modals.open({
      children: (
        <ConfirmModalFrame
          title={`Would you like pharmacy to call you?`}
          onConfirm={async () => {
            modals.close("pharmacy-call");
            setData({
              ...data,
              pharmacyCall: true,
            });
            if (order?.id) {
              setSubmitting(true);
              await onOrderStatusChange(order.id, OrderStatus.WantsCall);
              setSubmitting(false);
            }
          }}
          onCancel={() => {
            modals.close("pharmacy-call");
          }}
        />
      ),
      onClose: () => {},
      modalId: "pharmacy-call",
      closeButtonProps: {
        display: "none",
      },
      centered: true,
      title: "Confirm",
      styles: {
        header: {
          display: "none",
        },
      },
    });
  };

  const keepMeOnHold = () => {
    modals.open({
      children: (
          <ConfirmModalFrame
              title="Would you like to put your Rx on hold?"
              onConfirm={async () => {
                modals.close("keep-me-on-hold");
                setData({
                  ...data,
                  onHold: true,
                });
                if (order?.id) {
                  setSubmitting(true);
                  await onOrderStatusChange(order.id, OrderStatus.KeepMeOnHold);
                  setSubmitting(false);
                }
              }}
              onCancel={() => {
                modals.close("keep-me-on-hold");
              }}
          />
      ),
      onClose: () => {},
      modalId: "keep-me-on-hold",
      closeButtonProps: {
        display: "none",
      },
      centered: true,
      title: "Confirm",
      styles: {
        header: {
          display: "none",
        },
      },
    });
  };


  useEffect(() => {
    const fetchRushOrderSetting = async () => {
      const settingDoc = await getDoc(doc(db, 'settings', 'rushOrder'));
      if (settingDoc.exists()) {
        setShowRushOrder(settingDoc.data().enabled);
      }
    };
    fetchRushOrderSetting();
  }, []);


  useEffect(() => {
    const calculateTotal = () => {
      // Calculate the medicine total
      const medicineTotal = data?.medications
          ?.filter((med) => !med.metadata![BatchRuleActionType.NoPayment])
          ?.reduce((acc, med) => acc + parseFloat(med.price.replace(/^\D+/g, "")), 0) || 0;
      // Add service fee from BILL_SUMMARY
      const serviceFee = BILL_SUMMARY.find(item => item.name === "Service Fee")?.price || "0";
      const serviceTotal = medicineTotal + parseFloat(serviceFee);
      // Add rush order fee if applicable
      return serviceTotal + (data.rushOrder ? 25 : 0);
    };
    const newTotal = calculateTotal();
    setData(prevData => ({
      ...prevData,
      shippingOption: "", // Clear shipping option
      totalAmount: newTotal,
    }));
  }, [data.medications, data.rushOrder, BILL_SUMMARY]);



  return (
    <Flex direction={"column"} className="responsive-card">
      <LoadingOverlay visible={submitting} />
      <StyledTitle mb={15}>My Prescriptions</StyledTitle>
      <InnerContainer>
        <Flex direction={"column"} gap={15} flex={2}>
          <Card w={"100%"}>
            <Text style={{ fontSize: 18 }} mb={10}>
              Summary
            </Text>
            <Flex direction={"column"}>
              {data.rushOrder ? (
                <Flex justify={"space-between"}>
                  <Text>Rush order</Text>
                  <Text style={{ textAlign: "left" }}>$25</Text>
                </Flex>
              ) : null}

              <Flex justify={"space-between"}>
                <Text>Medicines</Text>
                <Text style={{ textAlign: "left" }}>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(
                    data?.medications
                      ?.filter(
                        (med) => !med.metadata![BatchRuleActionType.NoPayment]
                      )
                      ?.reduce(
                        (acc, med: any) =>
                          acc + parseFloat(med.price.replace(/^\D+/g, "")),
                        0
                      ) || 0
                  )}
                </Text>
              </Flex>

              {BILL_SUMMARY.map((summary) => (
                <Flex justify={"space-between"} key={summary.name}>
                  <Text>{summary.name}</Text>
                  <Text style={{ textAlign: "left" }}>
                    {summary.price ? `$${summary.price}` : "TBD"}
                  </Text>
                </Flex>
              ))}
              <Flex justify={"space-between"}>
                <Text>Delivery</Text>
                <Text style={{ textAlign: "left" }}>
                  {" "}
                  $
                  {data.shippingOption &&
                  !isNaN(parseFloat(data.shippingOption))
                    ? parseFloat(data.shippingOption)
                    : "TBD"}
                </Text>
              </Flex>

              <Flex justify={"space-between"}>
                <Text>Total</Text>
                <Text style={{ textAlign: "left" }}>
                  {/* ${data?.rushOrder ? 1.5 + 25 : 1.5} */}$
                  {data?.totalAmount !== undefined
                    ? data.totalAmount.toFixed(2)
                    : null}
                </Text>
              </Flex>
            </Flex>
          </Card>

          <Flex direction={"column"} gap={10}>
            <Subtitle1>Need to tell us something?</Subtitle1>
            <Card w={"100%"}>
              <Textarea
                value={data.internalNotes}
                onChange={(e) =>
                  setData({
                    ...data,
                    internalNotes: e.target.value,
                  })
                }
                placeholder="Need to tell us something? Let us know here!"
                rows={6}
                resize="vertical"
              />
            </Card>
          </Flex>
          <Box
            style={{
              border: "1px solid black",
              display: "flex",
              padding: "10px",
            }}>
            <Flex
              style={{ textAlign: "center", alignSelf: "center" }}
              direction={"column"}
              gap={15}>
              <Subtitle1>
                Don’t need your Rx quite yet? Ask for a future ship date.
              </Subtitle1>
              <Subtitle2>Send us an email at support@theartofmed.com</Subtitle2>
              <Button onClick={pharmacyCall} size="lg" radius={"xl"}>
                Have the Pharmacy Call Me
              </Button>
              <Button onClick={keepMeOnHold} size="lg" radius={"xl"}>
                Keep my Rx on Hold
              </Button>
            </Flex>
          </Box>
        </Flex>
        <Flex direction={"column"} flex={4} gap={15}>
          <Flex direction={"column"} h={"auto"} style={{ overflow: "scroll" }}>
            <MedicationCard />
          </Flex>

          {showRushOrder && (
              <>
                <Checkbox
                    checked={data.rushOrder}
                    onChange={(e) => {
                      setData(prevData => ({
                        ...prevData,
                        rushOrder: e.target.checked,
                        shippingOption: "", // Clear shipping option
                      }));
                    }}
                    label={<Text>Rush Order ($25 fee)</Text>}
                />
              </>
          )}


          <Text>
            * Not applicable to all compounds. If we can't complete the order
            within 24 hours, you will be refunded the rush order fee.
          </Text>
          <Subtitle2>
            Compounded medications take on average 72 business hours (excluding
            weekend and holidays) to process before shipping. Shipping takes
            additional time and vary by carrier.
          </Subtitle2>
        </Flex>
      </InnerContainer>
    </Flex>
  );
}

export default Prescription;

const InnerContainer = styled.div`
  gap: 15px;
  display: flex;

  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }
`;

const StyledTitle = styled(Title)`
  font-family: DM Sans;
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;

const Subtitle1 = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.011em;
`;
const Subtitle2 = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
`;
