import {
  ActionIcon,
  Button,
  Card,
  Flex,
  Input,
  Select,
  Space,
  Text,
  Title,
} from "@mantine/core";
import styled from "styled-components";
import {
  BILL_SUMMARY,
  SHIPPING_100_OR_GREATER,
  SHIPPING_OVERNIGHT_UPS,
  SHIPPING_UNDER_100,
  SHIPPING_UNDER_100_NO_RULE,
  SHIPPING_100_OR_GREATER_NO_RULE,
} from "../../../constants";
import { IconCheck, IconChevronDown } from "@tabler/icons-react";
import ConfirmModalFrame from "../../../components/ConfirmModalFrame";
import { modals } from "@mantine/modals";
import { useAppData } from "../../../contexts/AppContext";
import { Step } from "../../../constants/types";
import { Fragment, useEffect } from "react";
import { useForm } from "@mantine/form";
import { BatchRuleActionType } from "../../../constants/enums";

export const whichShippingOptions = (order: any, data: any = undefined) => {
  let metadata = order?.metadata || {};

  let meds = data ? data?.medications : order?.meds || [];
  let isAnyOvernight = meds.some((med: any) => med?.overnight);
  let price =
    meds
      ?.filter((med: any) => !med.metadata![BatchRuleActionType.NoPayment])
      ?.reduce(
        (acc: number, med: any) =>
          acc + parseFloat(med.price.replace(/^\D+/g, "")) || 0,
        0
      ) || 0;

  if (metadata![BatchRuleActionType.Overnight] || isAnyOvernight) {
    return SHIPPING_OVERNIGHT_UPS;
  } else if (metadata![BatchRuleActionType.MustUseUPS]) {
    if (metadata![BatchRuleActionType.Under100Shipping] || price < 100) {
      return SHIPPING_UNDER_100;
    } else if (
      metadata![BatchRuleActionType.GreaterOrEqual100Shipping] ||
      price >= 100
    ) {
      return SHIPPING_100_OR_GREATER;
    }
  } else if (metadata![BatchRuleActionType.OvernightUPS33Shipping]) {
    return SHIPPING_OVERNIGHT_UPS;
  } else if (metadata![BatchRuleActionType.Under100Shipping] || price < 100) {
    return SHIPPING_UNDER_100_NO_RULE;
  } else if (
    metadata![BatchRuleActionType.GreaterOrEqual100Shipping] ||
    price >= 100
  ) {
    return SHIPPING_100_OR_GREATER_NO_RULE;
  }
};

function AddressDetails() {
  const { appFlow, setData, data, setDisableContinue, order } = useAppData();

  const waiveSignature = () => {
    modals.open({
      children: (
        <ConfirmModalFrame
          title={`Are you sure you want to waive your signature?`}
          message={`Our controlled substance policy requires a signature for delivery. If you waive, you agree:
 
		 For any lost packages, we will need approval from your provider for an early refill and need to charge the full amount for replacement. An investigation with the delivery service will also be mandated following a lost package claim.`}
          onConfirm={() => {
            modals.close("waive-signature");
            setData({
              ...data,
              signatureWaived: true,
            });
          }}
          onCancel={() => {
            modals.close("waive-signature");
          }}
        />
      ),
      onClose: () => {},
      modalId: "waive-signature",
      closeButtonProps: {
        display: "none",
      },
      centered: true,
      title: "Confirm remove",
      styles: {
        header: {
          display: "none",
        },
      },
    });
  };

  const form = useForm({
    initialValues: {
      ...data.addresses.shippingAddress,
    },
    validate: {
      // email: (value) => (value === "" ? "Email required" : null),
      addressLine1: (value) =>
        value === "" ? "Address Line 1 required" : null,
      // addressLine2: (value) =>
      //   value === "" ? "Address Line 2 required" : null,
      city: (value) => (value === "" ? "City required" : null),
      state: (value) => (value === "" ? "State required" : null),
      zipCode: (value) => (value === "" ? "Zip Code required" : null),
    },
  });

  useEffect(() => {
    setData((data) => ({
      ...data,
      addresses: {
        ...data.addresses,
        shippingAddress: {
          ...form.values,
        },
      },
    }));
  }, [form.values]);

  useEffect(() => {
    let shouldBeDisabled = !form.isValid() || !data.shippingOption;
    setDisableContinue({
      [Step.AddressDetails]: shouldBeDisabled,
    });
  }, [data, form.values]);

  useEffect(() => {
    form.setValues({ ...data.addresses.shippingAddress });
  }, [appFlow.currentStep]);

  return (
    <Flex direction={"column"} className="my-prescription-holder">
      <StyledTitle mb={10}>My Prescriptions</StyledTitle>
      <InnerContainer>
        <Flex direction={"column"} gap={15} flex={2} className="inner-container-address">
          <Card w={"100%"}>
            <Text style={{ fontSize: 18 }} mb={10}>
              Summary
            </Text>
            <Flex direction={"column"} gap={10}>
              {data.rushOrder ? (
                <Flex justify={"space-between"}>
                  <Text>Rush order</Text>
                  <Text style={{ textAlign: "left" }}>$25</Text>
                </Flex>
              ) : null}
              <Flex justify={"space-between"}>
                <Text>Medicines</Text>
                <Text style={{ textAlign: "left" }}>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(
                    data?.medications
                      ?.filter(
                        (med) => !med.metadata![BatchRuleActionType.NoPayment]
                      )
                      ?.reduce(
                        (acc, med: any) =>
                          acc + parseFloat(med.price.replace(/^\D+/g, "")),
                        0
                      ) || 0
                  )}
                </Text>
              </Flex>

              {BILL_SUMMARY.map((summary) => (
                <Flex justify={"space-between"} key={summary.name}>
                  <Text>{summary.name}</Text>
                  <Text style={{ textAlign: "left" }}>
                    {summary.price ? `$${summary.price}` : "TBD"}
                  </Text>
                </Flex>
              ))}

              {data.shippingOption ? (
                <Flex justify={"space-between"}>
                  <Text>Delivery</Text>
                  <Text style={{ textAlign: "left" }}>
                    $
                    {data.shippingOption &&
                    !isNaN(parseFloat(data.shippingOption))
                      ? parseFloat(data.shippingOption)
                      : 0}
                  </Text>
                </Flex>
              ) : null}

              <Flex gap={8} style={{ alignSelf: "flex-end" }}>
                <Text fw={500}>Total</Text>
                <Text fw={500}>${data?.totalAmount?.toFixed(2)}</Text>
              </Flex>
            </Flex>
          </Card>

          <Fragment>
            <Flex direction={"column"} gap={10}>
              <Subtitle1>
                Shipping Options <span style={{ color: "red" }}>*</span>
              </Subtitle1>

              <Select
                // style={{ display: "none" }}
                styles={{
                  input: {
                    padding: 30,
                    border: "none",
                    borderRadius: 8,
                  },
                  section: {
                    paddingRight: 30,
                  },
                }}
                clearable={false}
                allowDeselect={false}
                defaultValue={
                  data.shippingOption ||
                  whichShippingOptions(order, data)![0]?.label
                }
                value={data.shippingOption}
                onChange={(value) => {
                  let shippingAmount = 0;
                  if (value && !isNaN(parseFloat(value))) {
                    shippingAmount = parseFloat(value);
                  }

                  setData({
                    ...data,
                    shippingOption: value || "",
                    totalAmount:
                      shippingAmount +
                      (data.initialAmount || 0) +
                      (data?.rushOrder ? 25 : 0),
                  });
                }}
                placeholder="Shipping options"
                data={whichShippingOptions(order, data)}
                rightSection={
                  <ActionIcon variant="transparent">
                    <IconChevronDown />
                  </ActionIcon>
                }
              />
            </Flex>

            <Flex direction={"column"} gap={10}>
              {order?.medications?.some(
                (med) => med?.metadata![BatchRuleActionType.RequireSignature]
              ) ? (
                <Fragment>
                  {data.signatureWaived ? (
                    <Flex justify={"center"}>
                      <Text>Signature waived </Text>
                      <IconCheck />
                    </Flex>
                  ) : (
                    <>
                      <Subtitle1>
                        Signature required for this delivery.
                      </Subtitle1>
                      <Button
                        className="responsive-button"
                        onClick={waiveSignature}
                        size="lg"
                        radius={"md"}>
                        Would you like to waive your signature
                      </Button>
                    </>
                  )}
                </Fragment>
              ) : null}
            </Flex>
          </Fragment>
        </Flex>
        <Flex direction={"column"} flex={4} gap={15}>
          <Card w={"100%"} mih={"100%"}>
            <Text fw={500}>Shipping Address</Text>
            <Text size="sm">Where your medication will be shipped.</Text>

            <Space h="sm" />
            <Flex gap={5}>
              <Text size="sm">Street Address</Text>
              <div className="is-danger">*</div>
            </Flex>
            <Input
              maw={"500px"}
              radius="md"
              size="md"
              styles={{
                input: {
                  borderColor: "#6E23CF",
                  borderWidth: "2px",
                },
              }}
              placeholder="Street Address"
              {...form.getInputProps("addressLine1")}
            />
            <Space h="xs" />

            <Text size="sm">Street Address line 2</Text>

            <Input
              maw={"500px"}
              radius="md"
              size="md"
              styles={{
                input: {
                  borderColor: "#6E23CF",
                  borderWidth: "2px",
                },
              }}
              placeholder="Street address line 2"
              {...form.getInputProps("addressLine2")}
            />
            <Space h="xs" />

            <Flex
              direction={{ base: "column", sm: "row" }}
              gap={{ base: "sm", sm: "xs" }}>
              <Flex direction={"column"}>
                <Flex gap={5}>
                  <Text size="sm">City</Text>
                  <div className="is-danger">*</div>
                </Flex>
                <Input
                  maw={"500px"}
                  radius="md"
                  size="md"
                  styles={{
                    input: {
                      borderColor: "#6E23CF",
                      borderWidth: "2px",
                    },
                  }}
                  placeholder="City"
                  {...form.getInputProps("city")}
                />
              </Flex>

              <Flex direction={"column"}>
                <Flex gap={5}>
                  <Text size="sm">State / Province</Text>
                  <div className="is-danger">*</div>
                </Flex>
                <Input
                  maw={"500px"}
                  radius="md"
                  size="md"
                  styles={{
                    input: {
                      borderColor: "#6E23CF",
                      borderWidth: "2px",
                    },
                  }}
                  placeholder="State / Province"
                  {...form.getInputProps("state")}
                />
              </Flex>
            </Flex>
            <Space h="xs" />

            <Flex gap={5}>
              <Text size="sm">Postal / Zip Code</Text>
              <div className="is-danger">*</div>
            </Flex>
            <Input
              maw={"250px"}
              radius="md"
              size="md"
              styles={{
                input: {
                  borderColor: "#6E23CF",
                  borderWidth: "2px",
                },
              }}
              placeholder="Postal / Zip Code"
              type="number"
              {...form.getInputProps("zipCode")}
            />
            <Space h="md" />
          </Card>
        </Flex>
      </InnerContainer>
    </Flex>
  );
}

export default AddressDetails;

const InnerContainer = styled.div`
  gap: 15px;
  display: flex;

  @media screen and (max-width: 1500px) {
    flex-direction: column-reverse;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    height: 100%;    
  }
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
  .inner-container-address{
    @media screen and (max-width: 480px) {
      flex-direction: column-reverse !important;
    }
  }
  .my-prescription-holder{
    width: 100%;
  }
`;

const StyledTitle = styled(Title)`
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
`;

const Subtitle1 = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.011em;
`;
